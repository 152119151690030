
.scrollarea-content {
  margin: 0;
  padding: 0;
  position: relative;
}
.scrollarea-content:focus {
  outline: 0;
}
.scrollarea {
  position: relative;
  overflow: hidden;
}
.scrollarea .scrollbar-container {
  position: absolute;
  background: none;
  opacity: .1;
  z-index: 9999;
  transition: all .4s;
}
.scrollarea .scrollbar-container.horizontal {
  width: 100%;
  height: 10px;
  left: 0;
  bottom: 0;
}
.scrollarea .scrollbar-container.horizontal .scrollbar {
  width: 20px;
  height: 8px;
  background: #000000;
  margin-top: 1px;
  &:hover{
    background: #0F73C3;
  }
}
.scrollarea .scrollbar-container.vertical {
  width: 10px;
  height: 100%;
  right: 0;
  top: 0;
}
.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 8px;
  height: 20px;
  background: #000000;
  margin-left: 1px;
  &:hover{
    background: #0F73C3;
  }
}
.scrollarea .scrollbar-container:hover {
  background: grey;
  opacity: .6 !important;
}
.scrollarea .scrollbar-container.active {
  background: grey;
  opacity: .6 !important;
}
.scrollarea:hover .scrollbar-container {
  opacity: .3;
}
.scrollarea .scrollbar-container.vertical .scrollbar, 
.scrollarea .scrollbar-container.horizontal .scrollbar{
  opacity: 0;
  transition: opacity .4s;
}
.scrollarea.is-hovered > .scrollbar-container.vertical .scrollbar,
.scrollarea.is-hovered > .scrollbar-container.horizontal .scrollbar{
  opacity: 1;
}