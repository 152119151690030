.App {
  width: 100%;
  height: calc(100% - 35px);
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1800px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1600px !important;
  }
}

.bg-header{
  background-color: rgb(3,3,3);
  height: 35px;
  border-top: 3px solid #39a5dc;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.quantum-logo{
  height: 60px;
  width: 160px;
  margin-top: 5px;
  background: url(./assets/Quantum.svg) no-repeat;
  /* position: absolute; */
  background-size: 60%;
  margin-left: 10px;
}

.dropdown-menu{
  min-width: 80px !important;
}

.header-space{
  display: flex;
  justify-content: space-between;
}

.profile-menu{
  /* position: absolute;
  right: 30px;
  top: 8px; */
  color: white;
  font-size: 12px;
}

.logout{
  /* margin-right: 10px; */
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  fill: black;
  width: 24px;
  height: 24px;
  background: url(./assets/logout.svg) no-repeat;
}

.check-mark-icon{
  height: 37px;
  margin-left: 40%;
  background: url(./assets/check-icon-mark.svg) no-repeat;
}

.check-mark-icon-right{
  height: 37px;
  margin-left: 44%;
  background: url(./assets/check-icon-mark.svg) no-repeat;
}

.no-sign-icon{
  opacity: 0.7;
  height: 28px;
  margin-left: 45%;
  background: url(./assets/x-icon.svg) no-repeat;
}

.profile{
    position: absolute;
    right: 50px;
    top: 1px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: url(./assets/profile.svg) no-repeat;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
