$white: #ffffff;
$black: #000000;
$grey: #ccc;

.m-accordion {
    border: 1px solid $grey;
    background: $white;
    color: inherit;
    border-radius: 5px;
    width: 100%;
    > .m-details {
        &:not(:first-child) {
            > .m-summary {
                border-top: 1px solid $grey;
            }
        }
    }
    .m-disabled {
        opacity: 0.5;
        .m-summary {
            cursor: default;
        }
        //nullify disable effect here
        > .m-summary:hover, > .m-summary:focus{
            opacity: 1;
            background-color: transparent;
        }
    }
}
.arrow-cont{
    width: 30px !important;
    display: flex;
    align-items: center;
}
.m-details {
    > .m-summary {
        display: flex;
        //align-items: center;
        justify-content: space-between;
        cursor: pointer;
        outline: 0;
        //padding: 10px 15px;
        transition: all .25s ease;
        height: 70px;
        margin: 8px
        &:hover, &:focus {
            //background-color: #cccccc;
            //opacity: 0.75
        }
        &.m-transitioning, &.m-transitioning *{
            user-select: none;
        }
    }
    .m-acc-content-panel {
        padding: 10px;
    }
}

.m-arrow-down,.m-arrow-up {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $black;
    border-radius: 5px;
    transition: all .25s ease;
}
.m-arrow-up {
    transform: rotate(-180deg);
}

.hide-arrow{
    visibility: hidden;
}