/** Checkbox - Radio button design**/

/**/

.checkbox-custom,
.radio-custom {
  opacity: 0;
}

input:disabled + button.radio-custom-label, input:disabled + button.checkbox-custom-label,
input:disabled + button.p3-checkbox-switch{ 
  opacity: 0.5;
  cursor: default;
  &:hover {
    &:before {
      border: 1px solid #8e8e8e !important;
      box-shadow: none !important;
      cursor: default;
    }
  }
  & label{
    cursor: default;
  }
}

input:checked:disabled + button.radio-custom-label {
  &:hover {
    &:before {
  box-shadow: inset 0px 0px 0px 2px #ffffff !important;
    }
  }
}




.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label,
.checkbox-switch-custom,
.p3-checkbox-switch {
  display: inline-block;
  vertical-align: middle;
  margin: 3.5px 0px !important;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label,
.p3-checkbox-switch {
  position: relative;
  text-align: left;
}
button.checkbox-custom-label {
  &:hover {
    &:before {
      border: 1px solid #0F73C3 !important;
      box-shadow: 1px 1px 3px 1px #bfcdd4;
    }
  }
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  background: #ffffff;
  border: 1px solid #87898b !important;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  padding: 0px;
  margin-right: 10px;
  text-align: center;
  /* --- for middle align adjustment --- */
  /* margin-top: -1px; */
  border-radius: 1px;
  /* fix for ie11 press effect on buttons */
  transform: translate(0, 0);
}

/* style to show highlight on checkbox/radio while focused */
button.checkbox-custom-label:focus.checkbox-custom-label:before {
  //   outline: 1px solid #0F73C3 !important;
  position: relative;
  border: 1px solid #0F73C3 !important;
  border-color: #0F73C3 !important;
  box-shadow: 1px 1px 3px 1px #bfcdd4;
}

/* style to show highlight on checkbox/radio while focused */
button.radio-custom-label:focus,
button.radio-custom-label:hover {
  &.radio-custom-label:before {
   // box-shadow: inset 0px 0px 0px 2px #ffffff, 0px 0px 0px 1px #bfcdd4;
    position: relative;
    border-color: #0F73C3 !important; 
  }
}
button.radio-custom-label.p3-no-btn-style,
button.checkbox-custom-label.p3-no-btn-style,
button.p3-checkbox-switch.p3-no-btn-style {
  border: none;
  background: none;
  font-family: inherit;
  font-size: 100%;
  outline: none;
  color: black;
  font-weight: 500;
}

button.radio-custom-label.p3-no-btn-style > label,
button.checkbox-custom-label.p3-no-btn-style > label,
button.p3-checkbox-switch.p3-no-btn-style > label {
  transform: translate(0px, 0px);
  /*  to avoid default effect for buttons in ie11 */
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\2713";
  font-weight: 900;
  font-size: 9px;
  color: #ffffff;
  background: #0F73C3;
  /* box-shadow: inset 0px 0px 0px 2px #ffffff; */
  border: 1px solid #0F73C3 !important;
  outline: none;
  border-radius: 1px;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
  vertical-align: sub;
}

.radio-custom:checked + .radio-custom-label:before {
  background: #FFFFFF;
  border-color: #0F73C3 !important;
  box-shadow: inset 0px 0px 0px 4px #0f73c3;
}

.p3-disabled-radio-checkbox {
  cursor: not-allowed;
  background-color: unset;
  color: unset;
  opacity: 0.5;
}

/* to reduce the radio/checkbox space in form */
.form-element .checkbox-custom + .checkbox-custom-label:before,
.form-element .radio-custom + .radio-custom-label:before {
  margin-right: 5px;
}

/* --- focus style ----  */

/* 
.checkbox-custom:focus + .checkbox-custom-label, .radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd; 
  
}
*/

.p3-checkbox-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 12px;
  border-radius: 25px;
}

.p3-checkbox-switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.p3-checkbox-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CBD4DA;
  -webkit-transition: .4s;
  transition: .4s;
}

.p3-checkbox-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -1px;
  bottom: -2px;
  background-color: #87898b;
  -webkit-transition: .4s;
  transition: .4s;
}

.checkbox-switch-custom:checked + .p3-checkbox-switch .p3-checkbox-slider:before {
  background-color: #0F73C3;
}

.checkbox-switch-custom:checked + .p3-checkbox-switch .p3-checkbox-slider {
  background-color: #bfcdd4;
}
.checkbox-switch-custom:focus + .p3-checkbox-switch .p3-checkbox-slider {
  box-shadow: 0 0 1px #0F73C3;
}

.checkbox-switch-custom:checked + .p3-checkbox-switch .p3-checkbox-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.p3-checkbox-slider.round {
  border-radius: 34px;
}

.p3-checkbox-slider.round:before {
  border-radius: 50%;
}