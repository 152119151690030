
.p3-license-field {
    max-width: 100px;
    padding: 5px !important;
    text-align: center;
    background: transparent;
}

.form-element .p3-license-error-border input, .p3-license-error-border input {
    border: 1px solid #ff0000;
}

.form-horizontal{
    padding-bottom: 10px;
}