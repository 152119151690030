.p3-data-grid-table {
  margin-bottom: 0px !important;
  border-spacing: 0px;
  width: 100%;
}

.p3-default-data-grid-row:hover {
  background-color: grey;
}

.p3-data-grid-table{
  .icon-column-item-renderer{
    vertical-align: middle;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: row;
  }
}

.p3-data-grid-table td {
  max-width: 100px;
}

.p3-data-grid-table td,
.p3-data-grid-header-title,
.p3-col-value {
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
  vertical-align: middle !important;
}

table.p3-data-grid-table th {
  border-top: none;
  border-right: 0.5px solid grey;
  position: relative;
  padding: 7px;
}

table.p3-data-grid-table th:last-child {
  border-top: none;
  border-right: none;
}
table.show-right-border th:last-child {
  border-right: 1px solid grey;
}

/* table.p3-data-grid-table th:last-child, table.p3-data-grid-table td:last-child{
  border-right: thin solid #eeeeee;
} */

//.p3-data-grid-table tbody tr {
  //position: relative;
//}

.p3-data-grid-table tbody tr:last-child {
  border-right: none;
}

.p3-data-grid-table tbody tr:nth-child(even) {
  /*background-color: #EECCCC;*/
  backface-visibility: visible;
}

.p3-data-grid-table-header {
  margin-bottom: 0;
  height: 10%;
  max-height: 24px;
  background-color: #FFFFFF;
  /* To fix the box-shadow not visible issue in IE */
  border-collapse: separate;
}

.p3-data-grid-header-title {
  width: auto;
  float: left;
}

.p3-data-grid-table tbody tr:nth-child(even) {
  border-bottom: 0;
}
.p3-tbody-holder tbody tr:hover {
  background-color: #E3FCEF75;
}

.p3-data-grid-table tbody tr.selected {
  background-color: #E3FCEF;
}

.p3-data-grid-table tbody tr td:last-child {
  border-right: none;
}
.p3-data-grid-table tbody tr td {
    text-align: left;
    //padding-left: 4px;
    border-top: solid 0.5px rgba(128, 128, 128, 0.5);
    border-right: solid 0.5px rgba(128, 128, 128, 0.5);
    position: relative;
    border-bottom: 0px;
}

.p3-show-pointer {
  cursor: pointer;
  //background-color: #F4F5F7;
}

.p3-show-arrow {
  cursor: default;
  background-color: #F4F5F7 !important;
}

.p3-data-grid-table-holder > div:nth-child(2) {
  overflow-y: auto;
}

.p3-table-20 {
  height: 20%;
}

.p3-table-80 {
  height: 80%;
}

.p3-table-30 {
  height: 30%;
}

.p3-table-70 {
  height: 70%;
}

.p3-table-80 .p3-table-container-scroll {
  height: 80%;
}

.p3-panel-with-list table th,
.p3-data-grid-table-header table th {
  border-top: none;
  border-right: 0.5px solid grey;
}

.p3-panel-with-list table th:last-child,
.p3-data-grid-table-header table th:last-child {
  border-top: none;
  border-right: none;
}

.popover.bs-tether-element-attached-top,
.popover.bs-tether-element-attached-right,
.popover.bs-tether-element-attached-left,
.popover.popover-bottom {
  z-index: 100000;
}

.p3-wrapword {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;
  /*Chrome & Safari */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  word-break: break-word;
  white-space: normal;
}

.p3-tbody-holder {
  position: relative;
  height: calc(100% - 36px) !important;
  overflow: hidden;
}

/* data table body height = table container height - header height(38) - pagination height(26) */
.p3-grid-with-pagination-height {
  height: calc(100% - 38px - 26px) !important;
}

.p3-parent-blocker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #999;
  opacity: 0.25;
}

.p3-dg-disabled-row {
  opacity: 0.35;
}

.p3-tbody-holder .p3-dg-disabled-row:hover {
  background-color: #FFFFFF;
}

.p3-dg-cell-holder {
  position: relative;
}

.p3-dg-td {
  position: relative;
}

.p3-data-grid-table-holder {
  position: relative;
  height: auto;
  border: 1px solid #cbcbcb;
  background-color: #FFFFFF;
}

.p3-data-grid-table-holder.p3-data-grid-no-events {
  & .p3-resize-handle,
  & .p3-dg-action-field {
    pointer-events: none;
  }
  & .p3-dg-action-field {
    &.p3-sort-desc,
    &.p3-sort-asc,
    &.p3-sort-inactive {
      display: none;
    }
    &.p3-dg-apply-filter {
      opacity: 0.5;
    }
  }
  & table tr > th {
    cursor: not-allowed;
  }
}

/* while dragging, we will make cursor of entire table as col-resize so to avoid flickering of mouse cursor */
.p3-col-resize * {
  cursor: col-resize !important;
}
.p3-grid-pagination-holder {
  border-top: 1px solid grey;
}

.p3-grid-readOnly {
  opacity: 0.5;
}

.p3-copy-icon,
.p3-copied-icon {
  position: relative;
  width: 16px;
  height: 16px;
  float: right;
  transition: 0.8s;
  transform-style: preserve-3d;
  position: relative;
}

.p3-col-value {
  max-width: calc(100% - 18px);
  float: left;
}

.popover-body .p3-btn {
  margin-right: 0px;
  min-height: 0px;
  min-width: 0px;
  padding: 0.5rem 1.3rem;
}

:root {
  --expanded-row-bg-color: grey;
}
.p3-data-grid-table .p3-expanded-row td {
  border-bottom: none;
  background-color: var(--expanded-row-bg-color);
}

.p3-data-grid-table .p3-expanded-row + tr td {
  border: none;
  background-color: var(--expanded-row-bg-color);
}

.expander-holder {
  margin-right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 4px;
}
.expander {
  border: solid #87898b;
  border-width: 0 1px 1px 0;
  padding: 3px;
  display: inline-block;
  transform: rotate(-45deg);
}
.expander-holder:hover .expander {
  border-color: #222;
  border-width: 0 2px 2px 0;
}

.down {
  transform: rotate(45deg);
}
/*CSS for column resizer */
.p3-resize-handle {
  cursor: col-resize;
  right: 0;
  user-select: none;
  z-index: 1;
  opacity: 0;
}
.p3-resize-handle,
.p3-tbody-resize-handler {
  width: 5px;
  position: absolute;
  border-right: 2px solid rgba(56, 122, 180, 0.8);
  height: 100%;
  top: 0;
}
.p3-tbody-resize-handler {
  display: none;
  z-index: 2;
}
.p3-tBody-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}
.p3-thead-holder {
  overflow: hidden;
  //height: 36px;
  box-shadow: 0 7px 2px -5px rgba(#000000, 0.1);
}
/* Since we have column resize option, we show ellipsis for all cells */
.p3-data-grid-table tbody tr td > div,
.p3-data-grid-table tbody tr td > div > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* If datagrid doesn't have pagination, its height should be 100% */
.p3-data-grid-table-holder > .p3-scrollbar-area:only-child {
  height: 100%;
}

.filter-input {
  padding-right: 20px;
}
.filter-clearIcon {
  position: absolute;
  right: 4px;
  bottom: 7px;
  width: 16px;
  height: 16px;
  background: #0F73C350;
  border-radius: 10px;
  text-align: center;
  &:hover {
    background: #0F73C3;
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
  svg {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    cursor: pointer;
    float: left;
    margin-top: 2px;
    margin-left: 2px;
    path {
      fill: #565656;
    }
  }
}

.p3-data-grid-table.h-100.w-100 > colgroup {
  display: none;
}

.p3-data-grid-table tbody .no-data-container{
  display: table-cell;
}
