
.overlay {
    background: rgba(#000000, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9000;
    vertical-align: middle;
}
.p3-status-overlay.overlay {
    position: absolute;
    background: rgba(#f5f5f5, 0.5) !important;
}

.p3-right-panel .p3-status-overlay.overlay {
    position: absolute;
    background: rgba(#f5f5f5, 0.5) !important;
}
.no-modal {
    background: rgba(#000000, 0.0);
    pointer-events: none;
}
.p3-modal {
    min-width: 180px;
    min-height: 120px;
    position: relative;
    padding-top: 0rem;
    background: #ffffff;
    background-clip: padding-box;
    box-shadow: 0 6px 10px 0 rgba(#000000, 0.25);
    display: flex;
    flex-flow: column;
    border-radius: 6px;
    pointer-events: all;
    border: 1px solid #dddddd;
}

/* resetting the style for disabled input fields  */
.p3-modal input:disabled {
    opacity: 0.5;
    background-color: #ffffff;
}

.p3-modal input:disabled + .radio-custom-label, .p3-modal input:disabled + .checkbox-custom-label{
    opacity: 0.5;
}

.header {
    min-height: 38px;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    background-color: #ffffff;
    vertical-align: middle;
    display: flex;
    flex-flow: row;
    align-items: center;
    /* popup standardization - header alignment*/
    padding: 12px 15px 12px 20px;
    border-bottom: 1px solid #cbcbcb;
}

.content {
    position: relative;
    height: 85%;
    overflow: auto;
    /* popup standardization - content alignment */
    margin-bottom: 0px;
    padding: 15px 15px 20px 20px;
}
.p3-overflow-visible, .content.p3-overflow-visible{
    overflow: visible !important;
}

.p3-modal .p3-content-color{
    background-color: rgba(243, 243, 243, 0.8); /*hex code with opacity is not working in IE*/
}

.footerContent .p3-has-float-btn {
    position: absolute;
    width: calc(100% - 50px);
}
.footerContent {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 15px;
    min-height: 45px;
}
.footerContent button {
    text-transform: capitalize;
    background-color: #e6e6e6;
    border-color: #e7e7e7;
    padding: 4px 15px;
    cursor: pointer;
    &:disabled{
        cursor: default;
    }
}

.p3-desc-container {
    text-align: justify;
}

.p3-desc-border {
    border-bottom: 1px solid #87898b;
    margin-bottom: 15px;
}
.p3-desc-container .auto-desc,
.p3-desc-container .auto-desc div {
    padding-bottom: 8px;
}

.p3-desc-container .auto-desc:last-child,
.p3-desc-container .auto-desc div:last-child {
    padding-bottom: 0px;
}

.p3-desc-container.p3-desc-border > *:last-child {
    padding-bottom: 15px;
}
.p3-desc-container > *:last-child {
    padding-bottom: 0px;
}

.footerContent .p3-btn-container {
    display: block;
    text-align: center;
}
.footerContent .p3-btn-help {
    float: right;
    position: relative;
    margin-right: 0px !important;
}
.footerContent .p3-btn {
    margin-right: 10px
}

.footerContent .p3-btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0;
    border-color: #0F73C3;
}

.footerContent .p3-btn:hover:enabled{
    color: #292b2c;
    background-color: #e6e6e6;
    border-color: #0F73C3;
}

.footerContent .p3-btn-container button:last-child,
.footerContent .p3-btn-container input[type=button]:last-child{
    margin-right: 0px
}

.popup-icon-container{
    position: absolute;
    right: 0;
}

.minimizeIcon {
   height: 14px;
}

.gap-pointer{
    cursor: pointer;
    margin-right: 15px;
}

.disable-max-height{
    max-height: none !important;
    max-width: none !important;
    width: 98%  !important;
    height: 96%  !important;
    top: 0px !important;
    left: 0px !important;
}

.p3-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10%;
    width: 90%;
    line-height: 13px;
}

.p3-popup-title-icon {
    padding: 0 0 0 22px;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    padding: 3px 0px 2px 26px;
}

.form-element label.col-form-label{
    align-self: center;
}

.form-element textarea,
.form-element input {
    min-height: 25px;
    border: 1px solid #87898b;
    padding: 0.1em 0.1em 0.1em 0.3em;
    border-radius: 0px;
    color: #313131;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.form-element textarea:focus,
.form-element input:focus {
    background-color: #ffffff;
    border-color: #bfcdd4;
    outline: 0;
}

.form-element:last-child{
    margin-bottom: 0px !important;
}

.p3-err-border textarea,
.p3-err-border input[type=text] {
    outline: #ff0000 auto 0px !important;
    border: 1px solid #ff0000 !important;
}

.p3-error-message {
    color: #ff0000!important;
    display: inline-block;
    margin-top: 1px;
    margin-bottom: 1px;
    padding-left: 0.7em;
    font-size: 12px;
}
.p3-error-message.p3-last-err-normal{
    position: absolute;
    bottom: -20px;
    padding-left: 1.5em;
}

.p3-error-full{
    padding-left: 24px;
}

.p3-left-panel {
    border-right: 1px solid #cbcbcb;
}

.p3-right-panel {
    position: relative;
    height: calc(100% - 25px);
    padding-top: 15px;
    background-color: #ffffff;
}


.p3-modal .p3-left-panel .configure-vpg-desc{
    padding-right: 15px;
}
.p3-modal .p3-left-panel .configure-vpg-desc h4{
    padding-bottom: 6px;
}
.p3-modal .p3-left-panel .configure-vpg-desc div{
   padding-bottom: 12px;
}

.p3-modal .p3-left-panel .configure-vpg-desc div:last-child{
    padding-bottom: 0px;
 }


.p3-modal .p3-right-panel h4,
.p3-modal .p3-left-panel h4 {
    font-size: 12px;
    font-weight: 600;
}


.p3-modal .p3-aside-content h4 {
    padding-bottom: 6px;
    border-bottom: 2px solid #cbcbcb;
}
.p3-modal .p3-aside-content {
    height: calc(100% - 45px);
    overflow: hidden;
}

.p3-pl-0_7{
    padding-left: 0.7em !important;
}

.p3-item-action{
    display: inherit;
}

/* for adjusting the scrollbar of the form section */

.p3-modal .content .p3-form-details.p3-scroll-extend-container{
    margin-right: -14px !important;
}

.p3-modal .content .p3-scrollbar-content.p3-scroll-extend-content{
    padding-right: 14px !important;
}


legend, legend.col-auto{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0px;
    width: auto;
    padding: 0 5px 0 7px;
    background-color: transparent;    
}
/* end */

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.p3-check-avail {
    color: #0F73C3 !important;
    text-decoration: underline !important;
    cursor: pointer;
}

a,.anchor{
    &.p3-link-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
    }
}

.p3-duplicate-vol{
    color: #ff0000 !important;
    /* margin-top: -9px; */
}

.btn:active, .btn.active {
    background-image: none;
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(#000000,.125);
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.btn-default {
    color: #333;
    background-color: #f5f5f5;
    border-color:#333;
}



.p3-modal .p3-left-panel .p3-config-acuity-vpg-step-info .scrollarea-content {
    height: auto;
}

.p3-modal .p3-left-panel .p3-config-acuity-vpg-step-info{
    height: calc( 100% - 60px);
}