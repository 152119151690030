body {
  margin: 0;
}

html,
body,
#root{
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  height: calc(100% - 35px);
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1800px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1600px !important;
  }
}

.bg-header{
  background-color: rgb(3,3,3);
  height: 35px;
  border-top: 3px solid #39a5dc;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.quantum-logo{
  height: 60px;
  width: 160px;
  margin-top: 5px;
  background: url(../../static/media/Quantum.6bfb62f3.svg) no-repeat;
  /* position: absolute; */
  background-size: 60%;
  margin-left: 10px;
}

.dropdown-menu{
  min-width: 80px !important;
}

.header-space{
  display: flex;
  justify-content: space-between;
}

.profile-menu{
  /* position: absolute;
  right: 30px;
  top: 8px; */
  color: white;
  font-size: 12px;
}

.logout{
  /* margin-right: 10px; */
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  fill: black;
  width: 24px;
  height: 24px;
  background: url(../../static/media/logout.7cde5293.svg) no-repeat;
}

.check-mark-icon{
  height: 37px;
  margin-left: 40%;
  background: url(../../static/media/check-icon-mark.6856f47e.svg) no-repeat;
}

.check-mark-icon-right{
  height: 37px;
  margin-left: 44%;
  background: url(../../static/media/check-icon-mark.6856f47e.svg) no-repeat;
}

.no-sign-icon{
  opacity: 0.7;
  height: 28px;
  margin-left: 45%;
  background: url(../../static/media/x-icon.dced12d1.svg) no-repeat;
}

.profile{
    position: absolute;
    right: 50px;
    top: 1px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: url(../../static/media/profile.b7c38e22.svg) no-repeat;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.horizontalStep {
  display: flex;
  justify-content: center;
  padding-top: 20px; }

.step-circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: inline-block;
  line-height: 60px;
  text-align: center; }

.active-circle {
  background-color: #2684FF;
  color: #FFFFFF;
  font-size: 20px; }

.p3-btn {
  background-color: #2684FF !important;
  color: #FFFFFF !important;
  margin: 10px; }

.inactive-circle {
  background-color: #EDEDED;
  color: #97A0AF;
  font-size: 20px; }

.step-cont {
  width: 60px !important;
  display: flex;
  flex-direction: column;
  align-items: center; }

.content-page {
  height: calc(100% - 190px);
  min-width: 1080px; }

.other-page {
  height: calc(90% - 190px);
  min-width: 1080px; }

.step-desc {
  width: 110px;
  font-size: 11px; }

.step-parent {
  display: flex;
  align-items: baseline; }

.p3-button-container {
  text-align: right; }

.hideNext {
  visibility: hidden; }

.m-accordion {
  border: 1px solid #ccc;
  background: #ffffff;
  color: inherit;
  border-radius: 5px;
  width: 100%; }
  .m-accordion > .m-details:not(:first-child) > .m-summary {
    border-top: 1px solid #ccc; }
  .m-accordion .m-disabled {
    opacity: 0.5; }
    .m-accordion .m-disabled .m-summary {
      cursor: default; }
    .m-accordion .m-disabled > .m-summary:hover, .m-accordion .m-disabled > .m-summary:focus {
      opacity: 1;
      background-color: transparent; }

.arrow-cont {
  width: 30px !important;
  display: flex;
  align-items: center; }

.m-details > .m-summary {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  outline: 0;
  transition: all .25s ease;
  height: 70px;
  margin: 8px .m-details > .m-summary; }
  .m-details > .m-summary.m-transitioning, .m-details > .m-summary.m-transitioning * {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.m-details .m-acc-content-panel {
  padding: 10px; }

.m-arrow-down, .m-arrow-up {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000000;
  border-radius: 5px;
  transition: all .25s ease; }

.m-arrow-up {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.hide-arrow {
  visibility: hidden; }

.scrollarea-content {
  margin: 0;
  padding: 0;
  position: relative; }

.scrollarea-content:focus {
  outline: 0; }

.scrollarea {
  position: relative;
  overflow: hidden; }

.scrollarea .scrollbar-container {
  position: absolute;
  background: none;
  opacity: .1;
  z-index: 9999;
  transition: all .4s; }

.scrollarea .scrollbar-container.horizontal {
  width: 100%;
  height: 10px;
  left: 0;
  bottom: 0; }

.scrollarea .scrollbar-container.horizontal .scrollbar {
  width: 20px;
  height: 8px;
  background: #000000;
  margin-top: 1px; }
  .scrollarea .scrollbar-container.horizontal .scrollbar:hover {
    background: #0F73C3; }

.scrollarea .scrollbar-container.vertical {
  width: 10px;
  height: 100%;
  right: 0;
  top: 0; }

.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 8px;
  height: 20px;
  background: #000000;
  margin-left: 1px; }
  .scrollarea .scrollbar-container.vertical .scrollbar:hover {
    background: #0F73C3; }

.scrollarea .scrollbar-container:hover {
  background: grey;
  opacity: .6 !important; }

.scrollarea .scrollbar-container.active {
  background: grey;
  opacity: .6 !important; }

.scrollarea:hover .scrollbar-container {
  opacity: .3; }

.scrollarea .scrollbar-container.vertical .scrollbar,
.scrollarea .scrollbar-container.horizontal .scrollbar {
  opacity: 0;
  transition: opacity .4s; }

.scrollarea.is-hovered > .scrollbar-container.vertical .scrollbar,
.scrollarea.is-hovered > .scrollbar-container.horizontal .scrollbar {
  opacity: 1; }

.customerContainer {
  border-radius: 0; }

option {
  height: 30px; }

.customer-label {
  text-align: left;
  padding-left: 2px !important;
  font-size: 14px; }

.customer-container {
  border: solid 1px #DFE1E6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px; }

.p3-compute {
  display: flex;
  justify-content: space-between; }

.customer-input {
  background-color: #FAFBFC;
  border: solid 1px #DFE1E6;
  height: 28px;
  border-radius: 3px;
  padding-left: 5px !important;
  padding-right: 5px !important; }

.cam-size-cont {
  display: flex;
  flex-direction: column;
  background-color: #FAFBFC; }

.total-label {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  padding-left: 25px; }

.add-button {
  font-size: 20px;
  color: #0065FF;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.chassis-desc {
  font-size: 12px;
  white-space: normal; }

.error-cont {
  border: solid red 1px; }

.comp-disable {
  opacity: 0.5; }

.camera-size-container {
  border: solid 1px #DFE1E6;
  padding-bottom: 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 2px 2px #DDD; }

.cam-total {
  height: 70px;
  display: flex;
  background-color: #EDEDED;
  justify-content: space-between;
  margin-top: auto; }

.general-info {
  height: 70px;
  display: flex;
  background-color: #EDEDED;
  justify-content: space-around;
  margin-top: auto;
  align-items: center; }

.close-btn {
  float: right;
  margin: 5px;
  cursor: pointer;
  background-image: url(../../static/media/close-icon.d8b438cf.svg);
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  opacity: 0.5; }

.form-label {
  width: 300px; }

.form {
  display: flex;
  justify-content: center; }

.form-input {
  width: 300px;
  border: solid 1px #DFE1E6; }

.content-label {
  font-size: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 75px) !important; }

.content-label-details {
  font-size: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 110px) !important; }

.content-highlight {
  color: #2684FF; }

.content-highlight-details {
  color: #2684FF;
  margin-left: 0.5rem; }

.content-normal {
  color: #000000; }

.title-label {
  width: 75px !important;
  text-align: left;
  font-size: 14px; }

.title-label-details {
  width: 110px !important;
  text-align: left;
  font-size: 14px; }

.title-cont {
  align-items: center;
  text-align: left; }

.title-cont-details {
  align-items: center;
  text-align: left;
  display: flex; }

hr.solid {
  border-top: 1px solid #DFE1E6;
  margin-left: 30px;
  margin-right: 30px; }

.contact-container {
  border: solid 1px #DFE1E6; }

.camera-style {
  display: flex;
  padding-top: 20px;
  padding-left: 25px;
  text-align: left; }

.cont-label {
  text-align: left;
  padding-left: 0px !important;
  font-size: 14px; }

.content-labels {
  font-weight: bold;
  font-size: 18px; }

.p3-toggle {
  position: absolute;
  right: 40px; }

.p3-dashboard-vpg-dropdown {
  min-width: 218px; }

.dropdown {
  min-width: 200px;
  height: 30px;
  border: solid 1px #DEDEDE;
  background-color: #FAFBFC;
  border-radius: 3px;
  padding-left: 1px !important; }

.dropdown-option {
  padding: 5px; }

.config-label {
  width: 100%;
  font-weight: bold;
  text-align: left; }

.chassis-grid {
  height: 100%; }

.summary-grid .p3-tbody-holder .p3-show-arrow {
  background-color: white !important; }

.summary-grid table {
  margin-bottom: 0px;
  border-collapse: separate; }

.add-btn {
  display: flex;
  justify-content: center; }

.btn-disable {
  pointer-events: none;
  opacity: 0.4; }

.recommend {
  font-size: 0.9rem;
  background-color: #EDEDED;
  color: black;
  padding-left: 2px;
  border-radius: 3px; }

.chassis-select {
  padding-bottom: 20px; }

.export {
  display: flex;
  justify-content: right;
  padding: 20px; }

.cam-sizer-accordion .m-summary > div {
  order: 1;
  height: 60px;
  width: 100%;
  padding: 5px 0;
  box-sizing: content-box; }

.cam-sizer-accordion .m-details > .m-summary:focus, .cam-sizer-accordion .m-details > .m-summary:hover {
  background-color: transparent;
  opacity: 1; }

.cam-sizer-accordion .m-details {
  background-color: #F4F4F4;
  border: 1px solid rgba(145, 145, 145, 0.1);
  box-shadow: 0 2px 1px 0 #FFFFFF;
  margin: 10px 0;
  border-radius: 3px; }

.cam-sizer-accordion .m-details:first-child {
  margin: 0 0 10px 0 !important; }

.cam-sizer-accordion, .cam-sizer-accordion .m-summary {
  border: 0 !important;
  justify-content: flex-start; }

.p3-right-arrow {
  background-position: 1em 1em;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.p3-down-arrow {
  background-position: 1em 0.5em;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.p3-scrollbar-area {
  width: 100%;
  height: 100%; }

.p3-right-arrow,
.p3-down-arrow {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.25s cubic-bezier(0, 1, 0.5, 1); }

.p3-right-arrow, .p3-down-arrow, .m-details .p3-arrow {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAR0lEQVQImX3OoRGAMABD0XewAzshSzkcjj3YEYVgGEwFIjT2X34CBVXIihtzggceLAnuDW4wfsDVlCemIVW7qu54/btb0s0XWT0Mt/XKgkEAAAAASUVORK5CYII=); }

.div-disable {
  opacity: 0.5;
  pointer-events: none; }

.div-enable {
  opacity: 1;
  pointer-events: auto; }

.summary-title {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  padding-top: 5px; }

.chassis-header {
  text-align: left; }

.chassis-description {
  white-space: normal; }

.chassis-row {
  text-align: center;
  white-space: normal; }

.p3-datagrid-main-container {
  width: 100%;
  height: 175px;
  display: inline-block;
  background-color: #ffffff; }

.p3-tbody-holder.p3-tbody-holder {
  background-color: #FFFFFF; }

.summary-submit {
  padding: 0 5px 0 5px !important;
  margin: 0;
  margin-left: 10px; }

.info-icon {
  padding-top: 4px;
  cursor: pointer; }

.site-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

.chassis-model {
  font-size: 14px; }

.selected-nvr {
  background: rgba(15, 115, 195, 0.6); }

.smart-nvr-text {
  padding-top: 0.25rem; }

.marketo-form-div {
  display: inline-flex;
  margin-top: 20px;
  margin-bottom: 20px; }

.popup-div {
  margin-right: 20rem;
  margin-left: 20rem;
  box-shadow: 0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13), 0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12);
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-top: 4px solid #06C;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  padding: 3rem 5rem;
  font-weight: 500;
  font-size: large; }

.fake-submit-btn {
  position: relative;
  margin-left: -470px;
  z-index: 1;
  border: none;
  border-radius: 5px;
  padding: 12px 60px;
  display: none; }

.fake-btn-div {
  position: relative;
  margin-top: auto; }

.popup-background {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(90, 90, 90, 0.5);
  z-index: 9999; }

.p3-data-grid-table {
  margin-bottom: 0px !important;
  border-spacing: 0px;
  width: 100%; }

.p3-default-data-grid-row:hover {
  background-color: grey; }

.p3-data-grid-table .icon-column-item-renderer {
  vertical-align: middle;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row; }

.p3-data-grid-table td {
  max-width: 100px; }

.p3-data-grid-table td,
.p3-data-grid-header-title,
.p3-col-value {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle !important; }

table.p3-data-grid-table th {
  border-top: none;
  border-right: 0.5px solid grey;
  position: relative;
  padding: 7px; }

table.p3-data-grid-table th:last-child {
  border-top: none;
  border-right: none; }

table.show-right-border th:last-child {
  border-right: 1px solid grey; }

/* table.p3-data-grid-table th:last-child, table.p3-data-grid-table td:last-child{
  border-right: thin solid #eeeeee;
} */
.p3-data-grid-table tbody tr:last-child {
  border-right: none; }

.p3-data-grid-table tbody tr:nth-child(even) {
  /*background-color: #EECCCC;*/
  -webkit-backface-visibility: visible;
          backface-visibility: visible; }

.p3-data-grid-table-header {
  margin-bottom: 0;
  height: 10%;
  max-height: 24px;
  background-color: #FFFFFF;
  /* To fix the box-shadow not visible issue in IE */
  border-collapse: separate; }

.p3-data-grid-header-title {
  width: auto;
  float: left; }

.p3-data-grid-table tbody tr:nth-child(even) {
  border-bottom: 0; }

.p3-tbody-holder tbody tr:hover {
  background-color: #E3FCEF75; }

.p3-data-grid-table tbody tr.selected {
  background-color: #E3FCEF; }

.p3-data-grid-table tbody tr td:last-child {
  border-right: none; }

.p3-data-grid-table tbody tr td {
  text-align: left;
  border-top: solid 0.5px rgba(128, 128, 128, 0.5);
  border-right: solid 0.5px rgba(128, 128, 128, 0.5);
  position: relative;
  border-bottom: 0px; }

.p3-show-pointer {
  cursor: pointer; }

.p3-show-arrow {
  cursor: default;
  background-color: #F4F5F7 !important; }

.p3-data-grid-table-holder > div:nth-child(2) {
  overflow-y: auto; }

.p3-table-20 {
  height: 20%; }

.p3-table-80 {
  height: 80%; }

.p3-table-30 {
  height: 30%; }

.p3-table-70 {
  height: 70%; }

.p3-table-80 .p3-table-container-scroll {
  height: 80%; }

.p3-panel-with-list table th,
.p3-data-grid-table-header table th {
  border-top: none;
  border-right: 0.5px solid grey; }

.p3-panel-with-list table th:last-child,
.p3-data-grid-table-header table th:last-child {
  border-top: none;
  border-right: none; }

.popover.bs-tether-element-attached-top,
.popover.bs-tether-element-attached-right,
.popover.bs-tether-element-attached-left,
.popover.popover-bottom {
  z-index: 100000; }

.p3-wrapword {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;
  /*Chrome & Safari */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  word-break: break-word;
  white-space: normal; }

.p3-tbody-holder {
  position: relative;
  height: calc(100% - 36px) !important;
  overflow: hidden; }

/* data table body height = table container height - header height(38) - pagination height(26) */
.p3-grid-with-pagination-height {
  height: calc(100% - 38px - 26px) !important; }

.p3-parent-blocker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #999;
  opacity: 0.25; }

.p3-dg-disabled-row {
  opacity: 0.35; }

.p3-tbody-holder .p3-dg-disabled-row:hover {
  background-color: #FFFFFF; }

.p3-dg-cell-holder {
  position: relative; }

.p3-dg-td {
  position: relative; }

.p3-data-grid-table-holder {
  position: relative;
  height: auto;
  border: 1px solid #cbcbcb;
  background-color: #FFFFFF; }

.p3-data-grid-table-holder.p3-data-grid-no-events .p3-resize-handle,
.p3-data-grid-table-holder.p3-data-grid-no-events .p3-dg-action-field {
  pointer-events: none; }

.p3-data-grid-table-holder.p3-data-grid-no-events .p3-dg-action-field.p3-sort-desc, .p3-data-grid-table-holder.p3-data-grid-no-events .p3-dg-action-field.p3-sort-asc, .p3-data-grid-table-holder.p3-data-grid-no-events .p3-dg-action-field.p3-sort-inactive {
  display: none; }

.p3-data-grid-table-holder.p3-data-grid-no-events .p3-dg-action-field.p3-dg-apply-filter {
  opacity: 0.5; }

.p3-data-grid-table-holder.p3-data-grid-no-events table tr > th {
  cursor: not-allowed; }

/* while dragging, we will make cursor of entire table as col-resize so to avoid flickering of mouse cursor */
.p3-col-resize * {
  cursor: col-resize !important; }

.p3-grid-pagination-holder {
  border-top: 1px solid grey; }

.p3-grid-readOnly {
  opacity: 0.5; }

.p3-copy-icon,
.p3-copied-icon {
  position: relative;
  width: 16px;
  height: 16px;
  float: right;
  transition: 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative; }

.p3-col-value {
  max-width: calc(100% - 18px);
  float: left; }

.popover-body .p3-btn {
  margin-right: 0px;
  min-height: 0px;
  min-width: 0px;
  padding: 0.5rem 1.3rem; }

:root {
  --expanded-row-bg-color: grey; }

.p3-data-grid-table .p3-expanded-row td {
  border-bottom: none;
  background-color: grey;
  background-color: var(--expanded-row-bg-color); }

.p3-data-grid-table .p3-expanded-row + tr td {
  border: none;
  background-color: grey;
  background-color: var(--expanded-row-bg-color); }

.expander-holder {
  margin-right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 4px; }

.expander {
  border: solid #87898b;
  border-width: 0 1px 1px 0;
  padding: 3px;
  display: inline-block;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.expander-holder:hover .expander {
  border-color: #222;
  border-width: 0 2px 2px 0; }

.down {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

/*CSS for column resizer */
.p3-resize-handle {
  cursor: col-resize;
  right: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 1;
  opacity: 0; }

.p3-resize-handle,
.p3-tbody-resize-handler {
  width: 5px;
  position: absolute;
  border-right: 2px solid rgba(56, 122, 180, 0.8);
  height: 100%;
  top: 0; }

.p3-tbody-resize-handler {
  display: none;
  z-index: 2; }

.p3-tBody-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1; }

.p3-thead-holder {
  overflow: hidden;
  box-shadow: 0 7px 2px -5px rgba(0, 0, 0, 0.1); }

/* Since we have column resize option, we show ellipsis for all cells */
.p3-data-grid-table tbody tr td > div,
.p3-data-grid-table tbody tr td > div > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* If datagrid doesn't have pagination, its height should be 100% */
.p3-data-grid-table-holder > .p3-scrollbar-area:only-child {
  height: 100%; }

.filter-input {
  padding-right: 20px; }

.filter-clearIcon {
  position: absolute;
  right: 4px;
  bottom: 7px;
  width: 16px;
  height: 16px;
  background: #0F73C350;
  border-radius: 10px;
  text-align: center; }
  .filter-clearIcon:hover {
    background: #0F73C3; }
    .filter-clearIcon:hover svg path {
      fill: #FFFFFF; }
  .filter-clearIcon svg {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    cursor: pointer;
    float: left;
    margin-top: 2px;
    margin-left: 2px; }
    .filter-clearIcon svg path {
      fill: #565656; }

.p3-data-grid-table.h-100.w-100 > colgroup {
  display: none; }

.p3-data-grid-table tbody .no-data-container {
  display: table-cell; }

/** Checkbox - Radio button design**/
/**/
.checkbox-custom,
.radio-custom {
  opacity: 0; }

input:disabled + button.radio-custom-label, input:disabled + button.checkbox-custom-label,
input:disabled + button.p3-checkbox-switch {
  opacity: 0.5;
  cursor: default; }
  input:disabled + button.radio-custom-label:hover:before, input:disabled + button.checkbox-custom-label:hover:before,
  input:disabled + button.p3-checkbox-switch:hover:before {
    border: 1px solid #8e8e8e !important;
    box-shadow: none !important;
    cursor: default; }
  input:disabled + button.radio-custom-label label, input:disabled + button.checkbox-custom-label label,
  input:disabled + button.p3-checkbox-switch label {
    cursor: default; }

input:checked:disabled + button.radio-custom-label:hover:before {
  box-shadow: inset 0px 0px 0px 2px #ffffff !important; }

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label,
.checkbox-switch-custom,
.p3-checkbox-switch {
  display: inline-block;
  vertical-align: middle;
  margin: 3.5px 0px !important;
  cursor: pointer; }

.checkbox-custom-label,
.radio-custom-label,
.p3-checkbox-switch {
  position: relative;
  text-align: left; }

button.checkbox-custom-label:hover:before {
  border: 1px solid #0F73C3 !important;
  box-shadow: 1px 1px 3px 1px #bfcdd4; }

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  background: #ffffff;
  border: 1px solid #87898b !important;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  padding: 0px;
  margin-right: 10px;
  text-align: center;
  /* --- for middle align adjustment --- */
  /* margin-top: -1px; */
  border-radius: 1px;
  /* fix for ie11 press effect on buttons */
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

/* style to show highlight on checkbox/radio while focused */
button.checkbox-custom-label:focus.checkbox-custom-label:before {
  position: relative;
  border: 1px solid #0F73C3 !important;
  border-color: #0F73C3 !important;
  box-shadow: 1px 1px 3px 1px #bfcdd4; }

/* style to show highlight on checkbox/radio while focused */
button.radio-custom-label:focus.radio-custom-label:before,
button.radio-custom-label:hover.radio-custom-label:before {
  position: relative;
  border-color: #0F73C3 !important; }

button.radio-custom-label.p3-no-btn-style,
button.checkbox-custom-label.p3-no-btn-style,
button.p3-checkbox-switch.p3-no-btn-style {
  border: none;
  background: none;
  font-family: inherit;
  font-size: 100%;
  outline: none;
  color: black;
  font-weight: 500; }

button.radio-custom-label.p3-no-btn-style > label,
button.checkbox-custom-label.p3-no-btn-style > label,
button.p3-checkbox-switch.p3-no-btn-style > label {
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
  /*  to avoid default effect for buttons in ie11 */ }

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\2713";
  font-weight: 900;
  font-size: 9px;
  color: #ffffff;
  background: #0F73C3;
  /* box-shadow: inset 0px 0px 0px 2px #ffffff; */
  border: 1px solid #0F73C3 !important;
  outline: none;
  border-radius: 1px; }

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
  vertical-align: sub; }

.radio-custom:checked + .radio-custom-label:before {
  background: #FFFFFF;
  border-color: #0F73C3 !important;
  box-shadow: inset 0px 0px 0px 4px #0f73c3; }

.p3-disabled-radio-checkbox {
  cursor: not-allowed;
  background-color: unset;
  color: unset;
  opacity: 0.5; }

/* to reduce the radio/checkbox space in form */
.form-element .checkbox-custom + .checkbox-custom-label:before,
.form-element .radio-custom + .radio-custom-label:before {
  margin-right: 5px; }

/* --- focus style ----  */
/* 
.checkbox-custom:focus + .checkbox-custom-label, .radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd; 
  
}
*/
.p3-checkbox-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 12px;
  border-radius: 25px; }

.p3-checkbox-switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.p3-checkbox-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CBD4DA;
  transition: .4s; }

.p3-checkbox-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -1px;
  bottom: -2px;
  background-color: #87898b;
  transition: .4s; }

.checkbox-switch-custom:checked + .p3-checkbox-switch .p3-checkbox-slider:before {
  background-color: #0F73C3; }

.checkbox-switch-custom:checked + .p3-checkbox-switch .p3-checkbox-slider {
  background-color: #bfcdd4; }

.checkbox-switch-custom:focus + .p3-checkbox-switch .p3-checkbox-slider {
  box-shadow: 0 0 1px #0F73C3; }

.checkbox-switch-custom:checked + .p3-checkbox-switch .p3-checkbox-slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px); }

/* Rounded sliders */
.p3-checkbox-slider.round {
  border-radius: 34px; }

.p3-checkbox-slider.round:before {
  border-radius: 50%; }

.p3-license-field {
  max-width: 100px;
  padding: 5px !important;
  text-align: center;
  background: transparent; }

.form-element .p3-license-error-border input, .p3-license-error-border input {
  border: 1px solid #ff0000; }

.form-horizontal {
  padding-bottom: 10px; }

.overlay {
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9000;
  vertical-align: middle; }

.p3-status-overlay.overlay {
  position: absolute;
  background: rgba(245, 245, 245, 0.5) !important; }

.p3-right-panel .p3-status-overlay.overlay {
  position: absolute;
  background: rgba(245, 245, 245, 0.5) !important; }

.no-modal {
  background: rgba(0, 0, 0, 0);
  pointer-events: none; }

.p3-modal {
  min-width: 180px;
  min-height: 120px;
  position: relative;
  padding-top: 0rem;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column;
  border-radius: 6px;
  pointer-events: all;
  border: 1px solid #dddddd; }

/* resetting the style for disabled input fields  */
.p3-modal input:disabled {
  opacity: 0.5;
  background-color: #ffffff; }

.p3-modal input:disabled + .radio-custom-label, .p3-modal input:disabled + .checkbox-custom-label {
  opacity: 0.5; }

.header {
  min-height: 38px;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  background-color: #ffffff;
  vertical-align: middle;
  display: flex;
  flex-flow: row;
  align-items: center;
  /* popup standardization - header alignment*/
  padding: 12px 15px 12px 20px;
  border-bottom: 1px solid #cbcbcb; }

.content {
  position: relative;
  height: 85%;
  overflow: auto;
  /* popup standardization - content alignment */
  margin-bottom: 0px;
  padding: 15px 15px 20px 20px; }

.p3-overflow-visible, .content.p3-overflow-visible {
  overflow: visible !important; }

.p3-modal .p3-content-color {
  background-color: rgba(243, 243, 243, 0.8);
  /*hex code with opacity is not working in IE*/ }

.footerContent .p3-has-float-btn {
  position: absolute;
  width: calc(100% - 50px); }

.footerContent {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 15px;
  min-height: 45px; }

.footerContent button {
  text-transform: capitalize;
  background-color: #e6e6e6;
  border-color: #e7e7e7;
  padding: 4px 15px;
  cursor: pointer; }
  .footerContent button:disabled {
    cursor: default; }

.p3-desc-container {
  text-align: justify; }

.p3-desc-border {
  border-bottom: 1px solid #87898b;
  margin-bottom: 15px; }

.p3-desc-container .auto-desc,
.p3-desc-container .auto-desc div {
  padding-bottom: 8px; }

.p3-desc-container .auto-desc:last-child,
.p3-desc-container .auto-desc div:last-child {
  padding-bottom: 0px; }

.p3-desc-container.p3-desc-border > *:last-child {
  padding-bottom: 15px; }

.p3-desc-container > *:last-child {
  padding-bottom: 0px; }

.footerContent .p3-btn-container {
  display: block;
  text-align: center; }

.footerContent .p3-btn-help {
  float: right;
  position: relative;
  margin-right: 0px !important; }

.footerContent .p3-btn {
  margin-right: 10px; }

.footerContent .p3-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
  border-color: #0F73C3; }

.footerContent .p3-btn:hover:enabled {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #0F73C3; }

.footerContent .p3-btn-container button:last-child,
.footerContent .p3-btn-container input[type=button]:last-child {
  margin-right: 0px; }

.popup-icon-container {
  position: absolute;
  right: 0; }

.minimizeIcon {
  height: 14px; }

.gap-pointer {
  cursor: pointer;
  margin-right: 15px; }

.disable-max-height {
  max-height: none !important;
  max-width: none !important;
  width: 98%  !important;
  height: 96%  !important;
  top: 0px !important;
  left: 0px !important; }

.p3-header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10%;
  width: 90%;
  line-height: 13px; }

.p3-popup-title-icon {
  padding: 0 0 0 22px;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  padding: 3px 0px 2px 26px; }

.form-element label.col-form-label {
  align-self: center; }

.form-element textarea,
.form-element input {
  min-height: 25px;
  border: 1px solid #87898b;
  padding: 0.1em 0.1em 0.1em 0.3em;
  border-radius: 0px;
  color: #313131;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.form-element textarea:focus,
.form-element input:focus {
  background-color: #ffffff;
  border-color: #bfcdd4;
  outline: 0; }

.form-element:last-child {
  margin-bottom: 0px !important; }

.p3-err-border textarea,
.p3-err-border input[type=text] {
  outline: #ff0000 auto 0px !important;
  border: 1px solid #ff0000 !important; }

.p3-error-message {
  color: #ff0000 !important;
  display: inline-block;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-left: 0.7em;
  font-size: 12px; }

.p3-error-message.p3-last-err-normal {
  position: absolute;
  bottom: -20px;
  padding-left: 1.5em; }

.p3-error-full {
  padding-left: 24px; }

.p3-left-panel {
  border-right: 1px solid #cbcbcb; }

.p3-right-panel {
  position: relative;
  height: calc(100% - 25px);
  padding-top: 15px;
  background-color: #ffffff; }

.p3-modal .p3-left-panel .configure-vpg-desc {
  padding-right: 15px; }

.p3-modal .p3-left-panel .configure-vpg-desc h4 {
  padding-bottom: 6px; }

.p3-modal .p3-left-panel .configure-vpg-desc div {
  padding-bottom: 12px; }

.p3-modal .p3-left-panel .configure-vpg-desc div:last-child {
  padding-bottom: 0px; }

.p3-modal .p3-right-panel h4,
.p3-modal .p3-left-panel h4 {
  font-size: 12px;
  font-weight: 600; }

.p3-modal .p3-aside-content h4 {
  padding-bottom: 6px;
  border-bottom: 2px solid #cbcbcb; }

.p3-modal .p3-aside-content {
  height: calc(100% - 45px);
  overflow: hidden; }

.p3-pl-0_7 {
  padding-left: 0.7em !important; }

.p3-item-action {
  display: inherit; }

/* for adjusting the scrollbar of the form section */
.p3-modal .content .p3-form-details.p3-scroll-extend-container {
  margin-right: -14px !important; }

.p3-modal .content .p3-scrollbar-content.p3-scroll-extend-content {
  padding-right: 14px !important; }

legend, legend.col-auto {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  width: auto;
  padding: 0 5px 0 7px;
  background-color: transparent; }

/* end */
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0; }

.p3-check-avail {
  color: #0F73C3 !important;
  text-decoration: underline !important;
  cursor: pointer; }

a.p3-link-disabled, .anchor.p3-link-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5; }

.p3-duplicate-vol {
  color: #ff0000 !important;
  /* margin-top: -9px; */ }

.btn:active, .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.btn-default {
  color: #333;
  background-color: #f5f5f5;
  border-color: #333; }

.p3-modal .p3-left-panel .p3-config-acuity-vpg-step-info .scrollarea-content {
  height: auto; }

.p3-modal .p3-left-panel .p3-config-acuity-vpg-step-info {
  height: calc( 100% - 60px); }

.p3-status-bar-status-icon {
  display: inline-block; }

.p3-status-bar-status-icon svg {
  vertical-align: middle; }

.p3-loader-info {
  margin-left: 10px; }

.p3-css-spinner {
  display: inline-block; }

.p3-alert-expand {
  margin-top: 10px;
  margin-left: 31px;
  display: flex;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.arrow-up {
  margin-left: 4px;
  width: 9px;
  height: 9px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.p3-arrow-rotate {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.p3-show-more {
  color: #007bff;
  text-decoration: underline; }

.p3-alert-max {
  overflow: hidden;
  margin: 10px;
  padding: 5px;
  border: solid 1px rgba(255, 0, 0, 0.75);
  border-radius: 4px;
  background-color: rgba(255, 0, 0, 0.1);
  display: flex; }

.p3-alert-details {
  padding: 5px; }

.p3-css-spinner.p3-progress-circle .p3-progress-child:before {
  background-color: #ffffff; }

.p3-css-spinner.p3-progress-circle {
  height: 24px;
  width: 24px; }

.p3-spinner-container {
  width: 26px; }

.p3-status-overlay.overlay {
  position: absolute;
  background: rgba(245, 245, 245, 0.5) !important;
  z-index: 100; }

.result-popup-container .content {
  padding: 1rem; }

.overlay .p3-loading-content-container {
  background: #ffffff;
  padding: 0.5em 1em;
  width: auto;
  min-width: 180px;
  max-width: 95%;
  box-shadow: 0 0 5px 2px #ccc;
  border-radius: 5px;
  left: auto; }

.p3-error-content {
  display: flex;
  width: 200px;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  background: #ffffff;
  padding: 0.5em 1em;
  width: auto;
  min-width: 180px;
  max-width: 500px;
  box-shadow: 0 0 5px 2px #ccc;
  border-radius: 5px;
  left: auto; }

.row.no-gutters.justify-content-center.h-100 {
  margin: auto; }

.row.no-gutters.justify-content-center.h-100 span {
  padding: 0; }

.p3-word-break {
  word-break: break-word; }

.row.no-gutters .col.align-self-center.justify-content-start {
  padding-right: 0px; }

.showIconClass div {
  margin-right: 8px; }

.showIconClass {
  max-height: 25px; }

.p3-alert-popup {
  max-height: 80%;
  max-width: 80%; }

.p3-alert-popup .p3-header-text {
  text-transform: lowercase; }

.p3-alert-popup .p3-header-text::first-letter {
  text-transform: capitalize; }

.p3-inline-warning {
  vertical-align: sub;
  height: 16px;
  padding-right: 3px; }

.p3-status-bar-status-icon .p3-custom-icon.queued-icon {
  fill: #565656; }

.p3-alert-popup .content {
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.p3-alert-popup .service_response_message pre {
  font-size: 12px; }

.p3-alert-popup .service_response_message h1, h2, h3 {
  font-size: 12px;
  font-weight: bold; }

