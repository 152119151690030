.customerContainer{
    border-radius: 0;
}
option {
    height: 30px;
}
.customer-label{
    text-align: left;
    padding-left: 2px !important;
    font-size: 14px;
}
.customer-container{
    border: solid 1px #DFE1E6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}

.p3-compute{
    display: flex;
    justify-content: space-between;
}

.customer-input{
    background-color: #FAFBFC;
    border: solid 1px #DFE1E6;
    height: 28px;
    border-radius: 3px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.cam-size-cont{
    display: flex;
    flex-direction: column;
    //border: solid 1px #DFE1E6;
    background-color: #FAFBFC;
}

.total-label{
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    padding-left: 25px;
}

.add-button{
    font-size: 20px;
    color: #0065FF;
    cursor: pointer;
    user-select: none;
}

.chassis-desc{
    font-size: 12px;
    white-space: normal;
}

.error-cont{
    border: solid red 1px;
}

.comp-disable{
    opacity: 0.5;
}

.camera-size-container{
    border: solid 1px #DFE1E6;
    padding-bottom: 10px;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 2px #DDD;
}

.cam-total{
    height: 70px;
    display: flex;
    background-color: #EDEDED;
    justify-content: space-between;
    margin-top: auto;
}

.general-info{
    height: 70px;
    display: flex;
    background-color: #EDEDED;
    justify-content: space-around;
    margin-top: auto;
    align-items: center;
}

.close-btn{
    float: right;
    margin: 5px;
    cursor: pointer;
    background-image: url("./close-icon.svg");
    width: 20px;
    height: 20px;
    //margin-left: auto;
    position: absolute;
    right:0;
    opacity:0.5;
}

.form-label{
    width: 300px;
}

.form{
    display: flex;
    justify-content: center;
}

.form-input{
    width: 300px;
    border: solid 1px #DFE1E6;
}

.content-label{
    font-size: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 75px) !important;
}

.content-label-details{
    font-size: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 110px) !important;
}

.content-highlight{
    color: #2684FF;
}

.content-highlight-details{
    color: #2684FF;
    margin-left: 0.5rem;
}

.content-normal{
    color: #000000;
}

.title-label{
    width: 75px !important;
    text-align: left;
    font-size: 14px;
}

.title-label-details{
    width: 110px !important;
    text-align: left;
    font-size: 14px;
}

.title-cont{
    align-items: center;
    text-align: left;
}

.title-cont-details{
    align-items: center;
    text-align: left;
    display: flex;
}

hr.solid {
    border-top: 1px solid #DFE1E6;
    margin-left: 30px;
    margin-right: 30px;
}

.contact-container{
    border: solid 1px #DFE1E6;
}

.camera-style{
    display: flex;
    padding-top: 20px;
    padding-left: 25px;
    text-align: left;
}

.cont-label{
    text-align: left;
    padding-left: 0px !important;
    font-size: 14px;
}

.content-labels{
    font-weight: bold;
    font-size: 18px;
}

.p3-toggle {
    position: absolute;
    right: 40px;
  }

.p3-dashboard-vpg-dropdown{
    min-width: 218px;
}
.dropdown{
    min-width: 200px;
    height: 30px;
    border: solid 1px #DEDEDE;
    background-color: #FAFBFC;
    border-radius: 3px;
    padding-left: 1px !important;
}

.dropdown-option {
    padding: 5px;
}
.config-label{
    width: 100%;
    font-weight: bold;
    text-align: left;
}

.chassis-grid{
    height: 100%;
}

.summary-grid .p3-tbody-holder .p3-show-arrow{
    background-color: white !important;
}

.summary-grid table{
    margin-bottom: 0px;
    border-collapse: separate;
}

.add-btn{
    display: flex;
    justify-content: center;
}

.btn-disable{
    pointer-events: none;
    opacity: 0.4;
}

.recommend{
    font-size: 0.9rem;
    background-color: #EDEDED;
    color: black;
    padding-left: 2px;
    border-radius: 3px;
}

.chassis-select{
    padding-bottom: 20px;
}

.export{
    display: flex;
    justify-content: right;
    padding: 20px;
}

.cam-sizer-accordion .m-summary>div {
    order: 1;
    height: 60px;
    width: 100%;
    //line-height: 24px;
    padding: 5px 0;
    box-sizing: content-box;
}

.cam-sizer-accordion .m-details>.m-summary:focus, .cam-sizer-accordion .m-details>.m-summary:hover {
    background-color: transparent;
    opacity: 1;
}

.cam-sizer-accordion .m-details {
    background-color: #F4F4F4;
    border: 1px solid hsla(0,0%,56.9%,.1);
    box-shadow: 0 2px 1px 0 #FFFFFF;
    margin: 10px 0;
    border-radius: 3px;
}

.cam-sizer-accordion .m-details:first-child{
    margin: 0 0 10px 0 !important;
}

.cam-sizer-accordion, .cam-sizer-accordion .m-summary {
    border: 0!important;
    justify-content: flex-start;
}

.p3-right-arrow {
	background-position: 1em 1em;
    transform: rotate(90deg);
}
.p3-down-arrow {
	background-position: 1em 0.5em;
	transform: rotate(-90deg);
}

.p3-scrollbar-area{
    width: 100%;
    height: 100%;
}

.p3-right-arrow,
.p3-down-arrow {
	width: 24px;
	height: 24px;
	background-position: center;
	background-repeat: no-repeat;
	transition: all 0.25s cubic-bezier(0, 1, 0.5, 1);
}

.p3-right-arrow, .p3-down-arrow, .m-details .p3-arrow {
    background-image: url("./Arrow.png");
}

.div-disable{
    opacity: 0.5;
    pointer-events: none;
}

.div-enable{
    opacity: 1;
    pointer-events:auto;
}

.summary-title{
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    padding-top: 5px;
}

.chassis-header{
    text-align: left;
}

.chassis-description{
    white-space: normal;
}

.chassis-row{
    text-align: center;
    white-space: normal;
}

.p3-datagrid-main-container {
    width: 100%;
    height: 175px;
    display: inline-block;
    background-color: #ffffff;
}

.p3-tbody-holder.p3-tbody-holder{
    background-color: #FFFFFF;
}

.summary-submit{
    padding: 0 5px 0 5px !important;
    margin: 0;
    margin-left: 10px;
}

.info-icon{
    padding-top: 4px;
    cursor: pointer;
}

.site-name{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.chassis-model{
    font-size: 14px;
}

.selected-nvr{
    background: rgba(15,115,195,0.6);
}

.smart-nvr-text{
    padding-top: 0.25rem;
}

.marketo-form-div{
    display: inline-flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.popup-div{
    margin-right: 20rem;
    margin-left: 20rem;
    box-shadow: 0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13), 0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12);
    position: absolute;
    top:40%;
    bottom:0;
    left:0;
    right:0;
    z-index:1;
    border-top: 4px solid #06C;
    height: fit-content;
    background-color: white;
    padding: 3rem 5rem;
    font-weight: 500;
    font-size: large;
}

.fake-submit-btn{
    position: relative;
    margin-left: -470px;
    z-index: 1;
    border:none;
    border-radius: 5px;
    padding: 12px 60px;
    display: none;
}

.fake-btn-div{
    position: relative;
    margin-top: auto;
}

.popup-background{
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(90, 90, 90, 0.5);
    z-index: 9999;
}