.horizontalStep{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.step-circle{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: inline-block;
    line-height: 60px;
    text-align: center;
}

.active-circle{
    background-color: #2684FF;
    color: #FFFFFF;
    font-size: 20px;
}

.p3-btn{
    background-color: #2684FF !important;
    color: #FFFFFF !important;
    margin: 10px;
}

.inactive-circle{
    background-color: #EDEDED;
    color: #97A0AF;
    font-size: 20px;
}

.step-cont{
    width: 60px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-page{
    //border: solid 1px #EDEDED;
    //margin-bottom: 20px;
    height: calc(100% - 190px);
    min-width: 1080px;
}

.other-page{
    height: calc(90% - 190px);
    min-width: 1080px;
}

.step-desc{
    width: 110px;
    font-size: 11px;
}

// .container{
//     display: flex;
//     flex-direction: column;
// }

.step-parent{
    display: flex;
    align-items: baseline;
}

.p3-button-container{
    text-align: right;
    //padding-bottom: 20px;
}

.hideNext{
    visibility: hidden;
}