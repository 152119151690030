
.p3-status-bar-status-icon {
    display: inline-block;   
}

.p3-status-bar-status-icon svg {
    vertical-align: middle;
}
.p3-loader-info {
    margin-left: 10px;
}
.p3-css-spinner {
    display: inline-block;
}
.p3-alert-expand{
    margin-top: 10px;
    margin-left: 31px;
    display: flex;
    cursor: pointer;
    width: fit-content;
}

.arrow-up {
    margin-left: 4px;
    width: 9px;
    height: 9px;
    transform: rotate(180deg);
}
.p3-arrow-rotate{
    transform: rotate(0deg);
}

.p3-show-more{
    color: #007bff;
    text-decoration: underline;
}


.p3-alert-max{
    overflow: hidden;
    margin: 10px;
    padding: 5px;
    border: solid 1px rgba(255, 0, 0, 0.75);
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
    display: flex;
}

.p3-alert-details{
    padding: 5px;
}

.p3-css-spinner.p3-progress-circle .p3-progress-child:before {
    background-color: #ffffff;
}
.p3-css-spinner.p3-progress-circle {
    height: 24px;
    width: 24px;
}

.p3-spinner-container{
    width: 26px;
}
.p3-status-overlay.overlay {
    position: absolute;
    background: rgba(#f5f5f5,0.5) !important;
    z-index: 100;
}
.result-popup-container .content {
    padding: 1rem;
}
.overlay .p3-loading-content-container {
    background: #ffffff;
    padding: 0.5em 1em;
    width: auto;
    min-width: 180px;
    max-width: 95%;
    // border: 1px solid $border;
    box-shadow: 0 0 5px 2px #ccc;
    border-radius: 5px;
    left: auto;    
}

.p3-error-content {
    display: flex;
    width: 200px;
    align-items: center;
    gap: 10px;
    background: #ffffff;
    padding: 0.5em 1em;
    width: auto;
    min-width: 180px;
    max-width: 500px;
    box-shadow: 0 0 5px 2px #ccc;
    border-radius: 5px;
    left: auto;    
}

.row.no-gutters.justify-content-center.h-100 {
    margin: auto;
}
.row.no-gutters.justify-content-center.h-100 span {
    padding: 0;
}
.p3-word-break{
    word-break: break-word;
}

.row.no-gutters .col.align-self-center.justify-content-start{
    padding-right: 0px;
}

.showIconClass div{
    margin-right: 8px;
}

.showIconClass{
    max-height: 25px;
}
.p3-alert-popup{
    max-height: 80%;
    max-width: 80%;
}

.p3-alert-popup .p3-header-text{
    text-transform: lowercase;
}
.p3-alert-popup .p3-header-text::first-letter{
    text-transform: capitalize;
}
.p3-inline-warning{
    vertical-align:sub;
    height:16px;
    padding-right: 3px;
}

.p3-status-bar-status-icon {
    .p3-custom-icon.queued-icon{
        fill: #565656;
    }
}

.p3-alert-popup .content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.p3-alert-popup .service_response_message pre {
    font-size: 12px;
}

.p3-alert-popup .service_response_message h1,h2,h3 {
    font-size: 12px;
    font-weight: bold;
}